<template>

<app-wrapper class="logos">

	<h2>{{ $state.page.logos_title }}</h2>
	
	<app-wrapper class="logos-wrapper" :content="true">

		<i class="fa-solid fa-angle-left logos-left" v-on:click="onBackClick"></i>
		<i class="fa-solid fa-angle-right logos-right"  v-on:click="onForwardClick"></i>	

		<app-wrapper v-for="set in sets" :key="set" class="logos-set" :class="{'is-active': set - 1 === active, 'is-left': set - 1 < active, 'is-right': set - 1 > active}">

			<div class="logos-set-item" v-for="(logo, index) in logos" :key="index" v-bgimage="logo" />

		</app-wrapper>

	</app-wrapper>

	<div class="logos-pips">
		<div v-for="set in sets" :key="set" class="logos-pips-item" :class="{'is-active': set - 1 === active}" v-on:click="onJumpClick(set - 1)" />
	</div>

</app-wrapper>

</template>

<script>

export default {

	data: function() {

		return {
			active: 0,
			delay: 10000,
			direction: 'forward'
		}

	},

	watch: {

		size: function() {

			this.active = 0

		}

	},

	created: function() {

		this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)

	},

	beforeDestroy: function() {

		clearTimeout(this.timer)

	},

	computed: {

		items: function() {

			return this.$state.page.logos_images

		},

		size: function() {

			if (this.window.is.desktop) {

				return 6

			} else if (this.window.is.tablet) {

				return 3

			} else {

				return 2

			}

		},

		sets: function() {

			return Math.ceil(this.items.length / this.size)

		},

		logos: function() {
			
			return this.$_.chunk(this.items, this.size)[this.active]

		}

	},

	methods: {

		onAutomateNext: function() {

			if (this.direction === 'forward') {

				this.onForwardClick()

			} else {

				this.onBackClick()

			}

		},

		onBackClick: function() {

			clearTimeout(this.timer)

			this.active = (this.active > 0) ? this.active - 1 : this.sets - 1

			this.direction = 'back'

			this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)

		},

		onForwardClick: function() {

			clearTimeout(this.timer)

			this.active = (this.active < this.sets - 1) ? this.active + 1 : 0

			this.direction = 'forward'

			this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)

		},

		onJumpClick: function(index) {

			clearTimeout(this.timer)

			this.active = index 

			this.direction = 'forward'

			this.timer = this.$_.delay(this.onAutomateNext.bind(this), this.delay)

		}

	}

}

</script>

<style scoped>

.logos {
	padding: 64px 0px;
}

.is-mobile .logos {
	padding: 40px 0px;
}

.logos h2 {
	font-size: 24px!important;
	font-weight: 400;
}

.is-mobile .logos h2 {
	font-weight: 300;
}

.logos-left,
.logos-right {
	font-size: 16px;
	width: 40px;
	line-height: 40px;
	height: 40px;
	text-align: center;
	color: #112A45;
	position: absolute;
	left: 14px;
	top: 50%;
	margin-top: -20px;
	cursor: pointer;
	z-index: 3;
	transition: all 100ms linear;
}

.is-mobile .logos-left,
.is-mobile .logos-right {
	margin-top: 0px;
	top: calc(100% + 26px);
}

.logos-right {
	right: 14px;
	left: auto;
}

.is-desktop .logos-left:hover,
.is-desktop .logos-right:hover {
	transform: scale(1.1);
}

.logos-wrapper {
	z-index: 2;
	padding: 0px 64px;
}

.logos-set {
	transition: all 300ms ease-in-out;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 20px;
}

.is-tablet .logos-set {
	grid-template-columns: 1fr 1fr 1fr;
}

.is-mobile .logos-set {
	grid-template-columns: 1fr 1fr;
}

.logos-set-item {
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	height: 128px;
}

.logos-set:not(.is-active) {
	position: absolute;
	top: 0px;
	transform: translateX(100%);
	opacity: 0;
}

.logos-set.is-left:not(.is-active) {
	transform: translateX(-100%);
}

.logos-set.is-right:not(.is-active) {
	transform: translateX(100%);
}

.logos-set.is-active {
	position: relative;
	transform: translateX(0%);
	opacity: 1;
}

.logos-pips {
	display: flex;
	justify-content: center;
	margin-top: 64px;
}

.is-mobile .logos-pips {
	margin-top: 40px;
}

.logos-pips-item {
	border: 2px solid #112A45;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin: 0px 5px;
	transition: all 100ms linear;
	cursor: pointer;
}

.logos-pips-item.is-active {
	border-color: #BBD9CD;
}

.is-desktop .logos-pips-item:hover {
	transform: scale(1.1);
}

</style>
